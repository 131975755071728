const state = {
  rewardDic: {},
}
const getters = {
  rewardDic: state => state.rewardDic,
}
const mutations = {
  updateDic(state, payload) {
    state.rewardDic = payload
  },
}
const actions = {
  updateDic({ commit }, payload) {
    commit('updateDic', payload)
  },
}
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
