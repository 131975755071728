import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import firebase from 'firebase/app'
// import VueGtag from 'vue-gtag'

// Routes
// import { canNavigate } from '@/libs/acl/routeProtection'
// import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
import apps from './routes/apps'
import dashboard from './routes/dashboard'
import uiElements from './routes/ui-elements/index'
import pages from './routes/pages'
import chartsMaps from './routes/charts-maps'
import formsTable from './routes/forms-tables'
import others from './routes/others'
import authentication from './routes/authentication'
import studentbasic from './routes/student/basic'
import jobseekerbasic from './routes/jobSeeker/basic'
import jobapplicantbasic from './routes/company/basic'
import admin from './routes/admin/basic'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'top' } },
    ...apps,
    ...dashboard,
    ...pages,
    ...chartsMaps,
    ...formsTable,
    ...uiElements,
    ...others,
    ...authentication,
    ...studentbasic,
    ...jobseekerbasic,
    ...jobapplicantbasic,
    ...admin,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  // window.console.log('memo', to.matched)
  // let currentUser = firebase.auth().currentUser
  if (requiresAuth) {
    firebase.auth().onAuthStateChanged(user => {
      // window.console.log('memo', user)
      if (user) {
        store.dispatch('userBasic/updateUid', user.uid)
        store.dispatch('userBasic/updateMail', user.email)
        next()
      } else {
        next({
          path: '/login',
          query: { redirect: to.path },
        })
      }
    })
  } else {
    next()
  }
})
// router.beforeEach((to, _, next) => {
//   const isLoggedIn = isUserLoggedIn()
//   window.console.log('🌺', !isLoggedIn, canNavigate(to))
//   if (!canNavigate(to)) {
//     // Redirect to login if not logged in
//     if (!isLoggedIn) return next({ name: 'login' })

//     // If logged in => not authorized
//     return next({ name: 'misc-not-authorized' })
//   }

//   // Redirect if logged in
//   if (to.meta.redirectIfLoggedIn && isLoggedIn) {
//     const userData = getUserData()
//     next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
//   }

//   return next()
// })

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})
// if (process.env.NODE_ENV === 'production') {
//   Vue.use(VueGtag, {
//     config: { id: 'UA-221025999-1' },
//   }, router)
// }

export default router
