export default [
  {
    path: '/access-control',
    name: 'access-control',
    component: () => import('@/views/extensions/acl/AccessControl.vue'),
    meta: {
      resource: 'ACL',
      action: 'read',
    },
  },
  // {
  //   path: '/top-old',
  //   name: 'top-old',
  //   component: () => import('@/pages/basic/brand/Top.vue'),
  //   meta: {
  //     layout: 'full',
  //   },
  // },
  {
    path: '/',
    name: 'top',
    component: () => import('@/pages/basic/brand/TopTmp.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/jobs',
    name: 'jobs',
    component: () => import('@/pages/basic/jobs/JobsListPublic.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/jobslist',
    name: 'jobslist',
    component: () => import('@/pages/basic/jobs/JobsList.vue'),
  },
  {
    path: '/joblist',
    name: 'joblist',
    component: () => import('@/pages/basic/jobs/JobsList.vue'),
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: () => import('@/pages/basic/brand/Privacy.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/app-privacy',
    name: 'app-privacy',
    component: () => import('@/pages/basic/brand/PrivacyApp.vue'),
  },
  {
    path: '/term',
    name: 'term',
    component: () => import('@/pages/basic/brand/Term.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/app-term',
    name: 'app-term',
    component: () => import('@/pages/basic/brand/TermApp.vue'),
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('@/pages/basic/contact/Contact.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/dataclean',
    name: 'dataclean',
    component: () => import('@/pages/data/DataCleaning1.vue'),
  },
]
