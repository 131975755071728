import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import ecommerceStoreModule from '@/views/apps/e-commerce/eCommerceStoreModule'
import profileBasic from '@/store/modules/profile/profileBasic'
import languageSkillBasic from '@/store/modules/languageSkill/languageSkillBasic'
import jobSkillBasic from '@/store/modules/jobSkill/jobSkillBasic'
import qualificationBasic from '@/store/modules/qualification/qualificationBasic'
import skillProductionBasic from '@/store/modules/skillProduction/skillProductionBasic'
import historyBasic from '@/store/modules/history/historyBasic'
import educationalBackgroundBasic from '@/store/modules/educationalBackground/educationalBackgroundBasic'
import verifyEmail from '@/store/modules/authentication/verifyEmail'
import jobApplicantBasic from '@/store/modules/company/jobApplicantBasic'
import jobSelection from '@/store/modules/company/jobSelection'
import lessonRegistrationBasic from '@/store/modules/lesson/lessonRegistrationBasic'
import lesson from '@/store/modules/lesson/lesson'
import jobSeekerBasic from '@/store/modules/jobSeeker/jobSeekerBasic'
import teacherRewardDic from '@/store/modules/teacher/teacherRewardDic'
import createPersistedState from 'vuex-persistedstate'
import payBasic from '@/store/modules/student/pay/payBasic'
import jobApplicantContest from '@/store/modules/company/jobApplicantContest'
import userBasic from '@/store/modules/user/userBasic'
import training from '@/store/modules/vocabulary/training'

import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    'app-ecommerce': ecommerceStoreModule,
    profileBasic,
    languageSkillBasic,
    jobSkillBasic,
    qualificationBasic,
    skillProductionBasic,
    historyBasic,
    educationalBackgroundBasic,
    jobApplicantBasic,
    jobSelection,
    lessonRegistrationBasic,
    lesson,
    jobSeekerBasic,
    teacherRewardDic,
    verifyEmail,
    payBasic,
    jobApplicantContest,
    userBasic,
    training,
  },
  strict: process.env.DEV,
  plugins: [createPersistedState()],
})
