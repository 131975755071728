export default [
  // ----------------------------------------------------------------------
  // 受講生プロフィール等
  // ----------------------------------------------------------------------
  {
    path: '/student-profile',
    name: 'student-profile',
    component: () => import('@/pages/student/profile/ProfileConfirm.vue'),
  },
  {
    path: '/student-profile-edit',
    name: 'student-profile-edit',
    component: () => import('@/pages/student/profile/ProfileEdit.vue'),
  },
  {
    path: '/student-basicsetting',
    name: 'student-basicsetting',
    component: () => import('@/pages/student/basicSetting/BasicSettingConfirm.vue'),
  },
  {
    path: '/student-basicsetting-edit',
    name: 'student-basicsetting-edit',
    component: () => import('@/pages/student/basicSetting/BasicSettingEdit.vue'),
  },
  {
    path: '/student-dashboad',
    name: 'student-dashboad',
    component: () => import('@/pages/student/dashboad/BasicDashboadConfirm.vue'),
  },
  {
    path: '/student-dashboad-main',
    name: 'student-dashboad-main',
    component: () => import('@/pages/student/dashboad/MainDashBoad.vue'),
  },
]
