const state = {
  jobApplicantDic: {},
  jobOpeningsList: [],
}
const getters = {
  jobApplicantDic: state => state.jobApplicantDic,
  jobOpeningsList: state => state.jobOpeningsList,
}
const mutations = {
  updateDic(state, payload) {
    state.jobApplicantDic = payload
  },
  updateList(state, payload) {
    state.jobOpeningsList = payload
  },
}
const actions = {
  updateDic({ commit }, payload) {
    commit('updateDic', payload)
  },
  updateList({ commit }, payload) {
    commit('updateList', payload)
  },
}
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
