// -------------------------------------------------------------
//  test 環境：collect.devteam@gmail.com
// -------------------------------------------------------------
export default {
  apiKey: 'AIzaSyCzo1Yb8NCqu7YuBwg_bIgyzt_l7NCfCy8',
  authDomain: 'collect-staging-env.firebaseapp.com',
  projectId: 'collect-staging-env',
  storageBucket: 'collect-staging-env.appspot.com',
  messagingSenderId: '39271244031',
  appId: '1:39271244031:web:dc383f994e1f900703acca',
}
// -------------------------------------------------------------
// -------------------------------------------------------------
//  本番 環境：collect.production.env@gmail.com
// -------------------------------------------------------------
// export default {
//   apiKey: 'AIzaSyBFmKXn682eWtVvg8ObWoWWcBKcAqrJz3M',
//   authDomain: 'ten-colle.firebaseapp.com',
//   projectId: 'ten-colle',
//   storageBucket: 'ten-colle.appspot.com',
//   messagingSenderId: '694757742441',
//   appId: '1:694757742441:web:44668cd106393439ae8ff8'
// }
// -------------------------------------------------------------
