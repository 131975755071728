const state = {
  language: '',
  category: '',
  wordStatus: '',
  countQuestion: 0,
  timer: 0,
  settingDic: {},
}
const getters = {
  coin: state => state.coin,
  language: state => state.language,
  category: state => state.category,
  wordStatus: state => state.wordStatus,
  countQuestion: state => state.countQuestion,
  timer: state => state.timer,
  settingDic: state => state.settingDic,
}
const mutations = {
  updateSetting(state, payload) {
    const {
      language, category, wordStatus, countQuestion, timer,
    } = payload
    // state.language = language
    // state.category = category
    // state.wordStatus = wordStatus
    // state.countQuestion = countQuestion
    // state.timer = timer
    const dic = {
      language: typeof language !== 'undefined' ? language : state.language,
      category: typeof category !== 'undefined' ? category : state.category,
      wordStatus: typeof wordStatus !== 'undefined' ? wordStatus : state.wordStatus,
      countQuestion: typeof countQuestion !== 'undefined' ? countQuestion : state.countQuestion,
      timer: typeof timer !== 'undefined' ? timer : state.timer,
    }
    state.settingDic = dic
  },
}
const actions = {
  updateSetting({ commit }, payload) {
    commit('updateSetting', payload)
  },
}
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
