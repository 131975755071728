export default [
  // ----------------------------------------------------------------------
  // 求職者プロフィール等
  // ----------------------------------------------------------------------
  {
    path: '/admin/account-list',
    name: 'admin-account-list',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/pages/admin/account/UserList.vue'),
  },
  {
    path: '/admin/user-list',
    name: 'admin-user-list',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/pages/admin/user/UserList.vue'),
  },
]
