export default [
  // ----------------------------------------------------------------------
  // 求人企業プロフィール等
  // ----------------------------------------------------------------------
  {
    path: '/company-basicsetting',
    name: 'company-basicsetting',
    component: () => import('@/pages/company/basicSetting/BasicSettingConfirm.vue'),
  },
  {
    path: '/company-basicsetting-edit',
    name: 'company-basicsetting-edit',
    component: () => import('@/pages/company/basicSetting/BasicSettingEdit.vue'),
  },
  {
    path: '/company-detailsetting',
    name: 'company-detailsetting',
    component: () => import('@/pages/company/detailSetting/DetailSettingConfirm.vue'),
  },
  {
    path: '/company-detailsetting-edit',
    name: 'company-detailsetting-edit',
    component: () => import('@/pages/company/detailSetting/DetailSettingEdit.vue'),
  },
  {
    path: '/job-jobapplicant-list',
    name: 'job-jobapplicant-list',
    component: () => import('@/pages/company/jobOpenings/JobOpeningsList.vue'),
  },
  {
    path: '/job-jobapplicant',
    name: 'job-jobapplicant',
    component: () => import('@/pages/company/jobOpenings/JobOpeningsConfirm.vue'),
  },
  {
    path: '/job-jobapplicant-edit',
    name: 'job-jobapplicant-edit',
    component: () => import('@/pages/company/jobOpenings/JobOpeningsEdit.vue'),
  },
  {
    path: '/job-jobselection',
    name: 'job-jobselection',
    component: () => import('@/pages/company/jobSelection/JobSelectionConfirm.vue'),
  },
  {
    path: '/job-jobselection-edit',
    name: 'job-jobselection-edit',
    component: () => import('@/pages/company/jobSelection/JobSelectionEdit.vue'),
  },
  {
    path: '/job-contest-list',
    name: 'job-contest-list',
    component: () => import('@/pages/company/contest/ContestList.vue'),
  },
  {
    path: '/job-contest',
    name: 'job-contest',
    component: () => import('@/pages/company/contest/ContestConfirm.vue'),
  },
  {
    path: '/job-contest-edit',
    name: 'job-contest-edit',
    component: () => import('@/pages/company/contest/ContestEdit.vue'),
  },
  {
    path: '/job-applylist',
    name: 'job-applylist',
    component: () => import('@/pages/company/applyHistory/ApplyList.vue'),
  },
  {
    path: '/job-search-list',
    name: 'job-search-list',
    component: () => import('@/pages/company/seachJobSeeker/Search.vue'),
  },
  {
    path: '/job-search-detail',
    name: 'job-search-detail',
    component: () => import('@/pages/company/seachJobSeeker/SearchDtail.vue'),
  },
  {
    path: '/job-scout',
    name: 'job-scout',
    component: () => import('@/pages/company/scout/Scout.vue'),
  },
  {
    path: '/job-favorite',
    name: 'job-favorite',
    component: () => import('@/pages/company/favorite/Favorite.vue'),
  },
  {
    path: '/job-dashboad',
    name: 'job-dashboad',
    component: () => import('@/pages/company/dashboad/MainDashBoad.vue'),
  },
  {
    path: '/job-price',
    name: 'job-price',
    component: () => import('@/pages/company/pricing/Pricing.vue'),
  },
]
