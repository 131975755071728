import mock from '@/@fake-db/mock'

// const date = new Date()
// const nextDay = new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
// prettier-ignore
// const nextMonth = date.getMonth() === 11 ? new Date(date.getFullYear() + 1, 0, 1) : new Date(date.getFullYear(), date.getMonth() + 1, 1)
// prettier-ignore
// const prevMonth = date.getMonth() === 11 ? new Date(date.getFullYear() - 1, 0, 1) : new Date(date.getFullYear(), date.getMonth() - 1, 1)

const data = {
  events: [
    {
      id: 1,
      url: '',
      title: '日本語基礎A',
      start: new Date(2021, 7, 1, 10, 30),
      end: new Date(2021, 7, 1, 12, 30),
      allDay: false,
      extendedProps: {
        calendar: 'レッスン完了',
      },
    },
    {
      id: 2,
      url: '',
      title: 'JavaScript基礎講座',
      start: new Date(2021, 7, 28, 10, 30),
      end: new Date(2021, 7, 28, 12, 30),
      allDay: false,
      extendedProps: {
        calendar: 'レッスン完了',
      },
    },
    {
      id: 3,
      url: '',
      title: 'よく分かるAWS',
      allDay: false,
      start: new Date(2021, 7, 12, 14, 30),
      end: new Date(2021, 7, 12, 15, 30),
      extendedProps: {
        calendar: 'レッスン完了',
      },
    },
    {
      id: 4,
      url: '',
      title: 'Linux入門',
      start: new Date(2021, 7, 8, 11, 30),
      end: new Date(2021, 7, 8, 12, 30),
      allDay: false,
      extendedProps: {
        calendar: '日時決定',
      },
    },
    {
      id: 5,
      url: '',
      title: 'SQL基礎講座',
      start: new Date(2021, 7, 6, 10, 30),
      end: new Date(2021, 7, 6, 12, 30),
      allDay: false,
      extendedProps: {
        calendar: '日時決定',
      },
    },
    {
      id: 6,
      url: '',
      title: 'データベースの仕組み',
      start: new Date(2021, 7, 2, 10, 30),
      end: new Date(2021, 7, 2, 12, 30),
      allDay: false,
      extendedProps: {
        calendar: '日時決定',
      },
    },
    {
      id: 7,
      url: '',
      title: '日本語基礎A',
      start: new Date(2021, 7, 18, 14, 30),
      end: new Date(2021, 7, 18, 15, 30),
      allDay: false,
      extendedProps: {
        calendar: 'レッスン完了',
      },
    },
    {
      id: 8,
      url: '',
      title: 'Java入門講座',
      start: new Date(2021, 7, 20, 10, 30),
      end: new Date(2021, 7, 20, 12, 30),
      allDay: false,
      extendedProps: {
        calendar: 'リクエスト中',
      },
    },
    {
      id: 9,
      url: '',
      title: '日本語基礎A',
      start: new Date(2021, 7, 22, 10, 30),
      end: new Date(2021, 7, 22, 12, 30),
      allDay: false,
      extendedProps: {
        calendar: 'リクエスト中',
      },
    },
    {
      id: 10,
      url: '',
      title: 'Monthly Checkup',
      start: new Date(2021, 7, 24, 10, 30),
      end: new Date(2021, 7, 24, 12, 30),
      allDay: false,
      extendedProps: {
        calendar: '日時決定',
      },
    },
  ],
}

// ------------------------------------------------
// GET: Return calendar events🔥
// ------------------------------------------------
mock.onGet('/apps/calendar/events').reply(config => {
  // Get requested calendars as Array
  const calendars = config.params.calendars.split(',')

  return [200, data.events.filter(event => calendars.includes(event.extendedProps.calendar))]
})

// ------------------------------------------------
// POST: Add new event
// ------------------------------------------------
mock.onPost('/apps/calendar/events').reply(config => {
  // Get event from post data
  const { event } = JSON.parse(config.data)

  const { length } = data.events
  let lastIndex = 0
  if (length) {
    lastIndex = data.events[length - 1].id
  }
  event.id = lastIndex + 1

  data.events.push(event)

  return [201, { event }]
})

// ------------------------------------------------
// POST: Update Event
// ------------------------------------------------
mock.onPost(/\/apps\/calendar\/events\/\d+/).reply(config => {
  const { event: eventData } = JSON.parse(config.data)

  // Convert Id to number
  eventData.id = Number(eventData.id)

  const event = data.events.find(e => e.id === Number(eventData.id))
  Object.assign(event, eventData)

  return [200, { event }]
})

// ------------------------------------------------
// DELETE: Remove Event
// ------------------------------------------------
mock.onDelete(/\/apps\/calendar\/events\/\d+/).reply(config => {
  // Get event id from URL
  let eventId = config.url.substring(config.url.lastIndexOf('/') + 1)

  // Convert Id to number
  eventId = Number(eventId)

  const eventIndex = data.events.findIndex(e => e.id === eventId)
  data.events.splice(eventIndex, 1)
  return [200]
})
