const state = {
  historyList: [],
}
const getters = {
  historyList: state => state.historyList,
}
const mutations = {
  updateList(state, payload) {
    state.historyList = payload
  },
  resetList(state) {
    state.historyList = []
  },
}
const actions = {
  updateList({ commit }, payload) {
    commit('updateList', payload)
  },
  resetList({ commit }) {
    commit('resetList')
  },
}
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
