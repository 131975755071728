const state = {
  PRDic: {},
  favoriteList: [],
}
const getters = {
  PRDic: state => state.PRDic,
  favoriteList: state => state.favoriteList,
}
const mutations = {
  updateDic(state, payload) {
    state.PRDic = payload
  },
  addFavoriteList(state, payload) {
    const index = state.favoriteList.findIndex(v => v.docID === payload.docID)
    window.console.log('memo', index)
    if (index === -1) state.favoriteList.push(payload)
  },
  deleteFromFavoriteList(state, payload) {
    state.favoriteList = state.favoriteList.filter(v => v.docID !== payload.docID)
  },
}
const actions = {
  updateDic({ commit }, payload) {
    commit('updateDic', payload)
  },
  addFavoriteList({ commit }, payload) {
    commit('addFavoriteList', payload)
  },
  deleteFromFavoriteList({ commit }, payload) {
    commit('deleteFromFavoriteList', payload)
  },
}
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
