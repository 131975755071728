const state = {
  uid: '',
  mail: '',
  userType: '',
  userNickName: '',
}
const getters = {
  uid: state => state.uid,
  mail: state => state.mail,
  userType: state => state.userType,
  userNickName: state => state.userNickName,
}
const mutations = {
  updateUid(state, payload) {
    state.uid = payload
  },
  updateMail(state, payload) {
    state.mail = payload
  },
  updateUserType(state, payload) {
    state.userType = payload
  },
  updateUserNickName(state, payload) {
    state.userNickName = payload
  },
  resetAll(state) {
    state.uid =  ''
    state.mail =  ''
    state.userType =  ''
    state.userNickName =  ''
  },
}
const actions = {
  updateUid({ commit }, payload) {
    commit('updateUid', payload)
  },
  updateMail({ commit }, payload) {
    commit('updateMail', payload)
  },
  updateUserType({ commit }, payload) {
    commit('updateUserType', payload)
  },
  updateUserNickName({ commit }, payload) {
    commit('updateUserNickName', payload)
  },
  resetAll({ commit }) {
    commit('resetAll')
  },
}
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
