export default [
  // ----------------------------------------------------------------------
  // 求職者プロフィール等
  // ----------------------------------------------------------------------
  {
    path: '/applylist',
    name: 'applylist',
    component: () => import('@/pages/jobSeeker/apply/ApplyList.vue'),
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('@/pages/jobSeeker/profile/ProfileConfirm.vue'),
  },
  {
    path: '/profile/edit',
    name: 'profile-edit',
    component: () => import('@/pages/jobSeeker/profile/ProfileEdit.vue'),
  },
  {
    path: '/hearing/edit',
    name: 'hearing-edit',
    component: () => import('@/pages/jobSeeker/hearing/HearingEdit.vue'),
  },
  {
    path: '/hearing',
    name: 'hearing',
    component: () => import('@/pages/jobSeeker/hearing/HearingConfirm.vue'),
  },
  {
    path: '/dashboad',
    name: 'dashboad',
    component: () => import('@/pages/jobSeeker/dashboad/Dashboad.vue'),
  },
]
